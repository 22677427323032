class VideoPrerollDetect {

  constructor(options) {
    this.options = options || {};
    this.detectBrowser();
    this.run();
    this.watch();
  }

  run() {
    let iframes = document.getElementsByTagName('iframe');
    for (let i = 0; i < iframes.length; i++) {
      this.process(iframes[i]);
    }
  }

  process(iframe) {
    let src = iframe.getAttribute('src');
    if (src) {
      let check = '';
      if (typeof URL === 'function') {
        try {
          check = new URL(src).hostname;
        } catch (error) {
        }
      } else {
        check = src;
      }

      if (check.indexOf('youtube.com') !== -1 || check.indexOf('youtu.be') !== -1) {
        let monetize = iframe.getAttribute('data-noads') ? false : true;
        if (monetize) {
          let videoId = this.getVideoId(src);
          if (videoId) {
            let poster = iframe.getAttribute('data-poster') ? iframe.getAttribute('data-poster') : '';
            let source = this.getSource(videoId, poster);
            if (this.isIE && this.IEVersion < 15) {
              let replace = document.createElement('iframe');
              replace.setAttribute('width', iframe.getAttribute('width'));
              replace.setAttribute('height', iframe.getAttribute('height'));
              iframe.parentNode.insertBefore(replace, iframe.nextSibling);
              iframe.style.display = 'none';
              let doc = replace.contentWindow.document;
              doc.open();
              doc.write(source);
              doc.close();
            } else if ('srcdoc' in iframe) {
              let replace = document.createElement('iframe');
              replace.setAttribute('width', iframe.getAttribute('width'));
              replace.setAttribute('height', iframe.getAttribute('height'));
              replace.setAttribute('srcdoc', source);
              iframe.parentNode.insertBefore(replace, iframe.nextSibling);
              iframe.style.display = 'none';
            } else {
              let url = this.getBlobUrl(source);
              iframe.setAttribute('src', url);
            }
          }
        }

      }



    }
  }

  getSource(videoId, poster) {
    const source = `
        <html>
          <head>
            <script src="https://imasdk.googleapis.com/js/sdkloader/ima3.js"></script>
            <script src="https://www.youtube.com/iframe_api"></script>
            <link href="https://play.bbend.com/preroll/v30/css/style.min.css" rel="stylesheet"/>
            <style>html {height:100%;} body {margin:0;padding:0;height:100%;overflow:hidden; background:#000;} iframe {border:none;width:100%;height:100%}</style>
          </head>
          <body>
            <div id="video" data-videoid="${videoId}" data-poster="${poster}">
              <video class="video-element video-js vjs-fluid vjs-16-9" controls playsinline webkit-playsinline preload="auto" width="560" height="315">
                <source src="https://play.bbend.com/preroll/v30/video/blank.mp4" type="video/mp4" />
              </video>
            </div>
            <script type="text/javascript">
            if(window.google && window.google.ima) {
              var script = document.createElement('script');
              script.src = 'https://play.bbend.com/preroll/v30/js/player.min.js';
              document.getElementsByTagName('head')[0].appendChild(script);
            }
            function onYouTubeIframeAPIReady() {
              if(window.google && window.google.ima) {
                return;
              }
              var player = new YT.Player(document.getElementById('video'), {
                height: 315,
                width: 560,
                videoId: "${videoId}",
                playerVars: {
                  autoplay: 0,
                  modestbranding: 1,
                  playsinline: 1
                },
                events: {
                  'onError': function(event) {
                    if(window.parent.ga) {
                      var message = '';
                      if(event.data === 2) {
                        message = 'The request contains an invalid parameter value';
                      } else if(event.data === 5) {
                        message = 'The requested content cannot be played in an HTML5 player or another error related to the HTML5 player has occurred';
                      } else if(event.data === 100) {
                        message = 'Video has been removed (for any reason) or has been marked as private';
                      } else if(event.data === 101 || event.data === 150) {
                        message = 'The owner of the requested video does not allow it to be played in embedded players';
                      } else {
                        message = 'Unknown error';
                      }
                      window.parent.ga('send', 'event', 'YouTube Video Error', window.parent.location.pathname, message);
                    }
                  }
                }
              });
            }
            </script>
          </body>
        </html>
    `;
    return source;
  }

  getBlobUrl(source) {
    let blob = new Blob([source], {
      type: 'text/html'
    });
    let url = URL.createObjectURL(blob);
    return url;
  }

  getVideoId(url) {
    let videoId;
    const parser = document.createElement('a');
    parser.href = url;
    const query = parser.search;
    const path = parser.pathname;
    if (query) {
      let params = {};
      let vars = query.split('&');
      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
      if (params.v) {
        videoid = params.v;
      }
    }
    if (!videoId) {
      let parts = path.split('/');
      parts = parts.filter(entry => entry !== '');
      videoId = parts[parts.length - 1];
    }
    return videoId;
  }

  watch() {
    if ('MutationObserver' in window) {
      this.observer = new MutationObserver((mutations, observer) => {
        this.onDomChange(mutations, observer);
      });
      this.observer.observe(document.querySelector('body'), {
        attributes: false,
        childList: true,
        subtree: true
      });
    }
  }

  onDomChange(mutations, observer) {
    for (let i = 0; i < mutations.length; i++) {
      let mutation = mutations[i];
      if (mutation.type == 'childList') {
        for (let j = 0; j < mutation.addedNodes.length; j++) {
          let node = mutation.addedNodes[j];
          if (node.tagName === 'IFRAME') {
            this.process(node);
          } else if (node.nodeType === Node.ELEMENT_NODE) {
            let iframes = node.querySelectorAll('iframe');
            for (let x = 0; x < iframes.length; x++) {
              this.process(iframes[x]);
            }
          }
        }
      }
    }
  }

  detectBrowser() {
    this.isIE = false;
    var match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(navigator.userAgent);
    if (match) {
      this.isIE = true;
      this.IEVersion = parseInt(match[2]);
    }
    this.isIOS = false;
    var match2 = /\b(iPhone|iPad)/.exec(navigator.userAgent);
    if (match2) {
      this.isIOS = true;
    }
  }

}

export {
  VideoPrerollDetect as
    default
};
